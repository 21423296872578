<template lang="pug">
#Form
  #navbar
    .header.mx-4.mb-5.pt-4(@click="goHome()")
      h4.light-font PLATAFORMA
      h4.bold-font CIUDADANA DE FOSAS
    .header.mx-5.mb-5.pt-4(@click="logout()")
      p.m-0 Salir

  #content
    .notification(
      v-bind:class="{active: activeNotification}"
      v-bind:style="{ backgroundColor: notification.color }"
    )
      p.mb-0 {{ notification.text }}
    router-view

</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      activeNotification: false,
    }
  },
  computed: {
    notification() {
      return this.$store.state.notification;
    },
    chooser: {
      get() {
        return this.$store.state.chooser
      },
      set(value) {
        this.$store.commit("setValue", {
          key: "chooser",
          value: value,
        })
      },
    },
    selectedType: {
      get() {
        return this.$store.state.lead.selectedType
      },
      set(value) {
        this.$store.commit("lead/setValue", {
          key: "selectedType",
          value: value,
        })
      },
    },
    testimonyTypes() {
      return this.$store.state.lead.testimonyTypes
    },
  },
  methods: {
    goHome() {
      if (this.$route.name !== "documents") {
        this.$router.push({ name: "documents" });
      }
    },
    logout() {
      this.$router.replace("/login");
      this.$store.state.user = {};
      delete axios.defaults.headers.post['X-CSRF-Token'];
      delete axios.defaults.headers.delete['X-CSRF-Token'];
      delete axios.defaults.headers.put['X-CSRF-Token'];
      this.$store.dispatch("logout");
      localStorage.clear();
    }
  },
  watch: {
    selectedType() {
      this.chooser = false
    },
    notification(notification) {
      if (notification.active === true) {
        this.activeNotification = true;
        setTimeout(() => {
          this.activeNotification = false;
          this.$store.commit("setNotification", {
            active: false,
            text: "",
            color: "",
          });
        }, 3000);
      }
    }
  },
  mounted() {
    this.selectedType = 0
    this.chooser = true
    if (this.$store.state.notification.active === true) {
      this.activeNotification = true;
      setTimeout(() => {
        this.activeNotification = false;
        this.$store.commit("setNotification", {
          active: false,
          text: "",
          color: "",
        });
      }, 3000);
    }
  },
}
</script>

<style lang="scss">
#navbar {
  display: flex;
  justify-content: space-between;
  .header {
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    color: white;
    cursor: pointer;
    align-self: center;
    h4.bold-font {
      color: #191a1a;
      background-color: #e0e0e0;
      width: fit-content;
      padding: 0px 5px;
    }
    h4.light-font {
      margin: auto;
      text-align: center;
    }
    p {
      font-size: 18px;
      text-decoration: underline;
    }
  }
}
#content {
  .notification {
    width: 200px;
    height: auto;
    display: none;
    position: fixed;
    top: 44%;
    z-index: 1;
    right: 1%;
    border-radius: 5px;
    padding: 10px;
  }
  .active {
    display: block !important;
  }
}
</style>
